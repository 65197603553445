import useRefetchByKeys from '@commonstock/client/src/react/useRefetchByKeys'
import { GlobalFeedPeriodType, GlobalFeedSortType } from '@commonstock/common/src/api/feed'
import { usePersistedState } from '@commonstock/common/src/utils/usePersistedState'
import { useRouter } from 'next/router'
import { useCallback, useMemo } from 'react'
import { isServer } from 'src/utils/isServer'
import { UrlObject } from 'url'
import { GlobalFeedPeriodKey, GlobalFeedSortKey } from '../feed/constants'
import { Routes } from './constants'

export function useGoto(url: UrlObject | string) {
  let router = useRouter()
  return useCallback(() => {
    // @ts-ignore not sure how to fix
    router.push(url)
  }, [router, url])
}

export function useGoToHome() {
  const [period, setPeriod, periodPending] = usePersistedState<GlobalFeedPeriodType>(
    GlobalFeedPeriodKey,
    GlobalFeedPeriodType.RECENT
  )
  const [sortBy, setSortBy, sortPending] = usePersistedState<GlobalFeedSortType>(
    GlobalFeedSortKey,
    GlobalFeedSortType.TOP
  )

  const router = useRouter()
  const isHome = !isServer && window.location.pathname === Routes.Home
  const homeLink: UrlObject = useMemo(() => ({ pathname: Routes.Home, query: { sort: sortBy, period } }), [
    period,
    sortBy
  ])

  const updateFeed = useRefetchByKeys('get-global-feed')

  const goToHome = useCallback(
    (e?: MouseEvent) => {
      if (e && 'preventDefault' in e) e.preventDefault()
      if (isHome) {
        updateFeed()
        router.push({ ...router, hash: 'refresh' })
      } else router.push(homeLink)
    },
    [homeLink, isHome, router, updateFeed]
  )

  return { period, setPeriod, periodPending, sortBy, setSortBy, sortPending, homeLink, goToHome }
}
